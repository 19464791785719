import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';

import { serviceMeuPlano } from '../services/meu-plano';
import {
  MethodFaturamento,
  MeuPlanoContextData,
  ScreenMeuPlano,
  SubScreenMeuPlano,
} from '../types/context';
import { useForm } from 'react-hook-form';

import {
  Ciclo,
  Contratacao,
  ContratacaoPlano,
  FormaPagamento,
} from '../types/Plan';
import { yupResolver } from '@hookform/resolvers/yup';
import creditCardSchema from '../components/CreditCard/validation';
import customerDataSchema from '../components/DadosCliente/validations';
import resetClientData from '../components/DadosCliente/reset.json';
import resetCreditCard from '../components/CreditCard/reset.json';
import api from '~/services/api';
import { CustomerData } from '../types/Customer';

// import dataJson from '../mock/plan.json';
import useAuth from '~/hooks/useAuth';
import { formatUtils } from '../utils/format-utils';

export const MeuPlanoContext = createContext({} as MeuPlanoContextData);

export const MeuPlanoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();
  const ofertaAtivaSemanaConsumidor = useCallback(
    (dataInicio: Date, dataFim: Date): boolean => {
      const hoje = new Date();
      const dataFimAjustada = new Date(dataFim.setHours(23, 59, 59, 999));
      return hoje >= dataInicio && hoje <= dataFimAjustada;
    },
    [],
  );

  const [isDemonstracao, setIsDemonstracao] = useState(true);
  const [screen, setScreen] = useState<ScreenMeuPlano>('HOME');
  const [subScreen, setSubScreen] = useState<SubScreenMeuPlano>('NENHUMA');
  const [planoPagamento, setPlanoPagamento] = useState<'MENSAL' | 'ANUAL'>(
    'ANUAL',
  );
  const [contratacaoPlano, setContratacaoPlano] =
    useState<ContratacaoPlano | null>(null);
  const [contratacaoCliente, setContratacaoCliente] =
    useState<Contratacao | null>(null);
  const [methodFaturamento, setMethodFaturamento] =
    useState<MethodFaturamento | null>(null);
  const [contratacaoClienteFuturo, setContratacaoClienteFuturo] =
    useState<Contratacao | null>({} as Contratacao);
  const [cicloAtual, setCicloAtual] = useState<Ciclo>({} as Ciclo);
  const [cicloFuturo, setCicloFuturo] = useState<Ciclo | null>({} as Ciclo);
  const [cicloFuturoFormaPagamento, setCicloFuturoFormaPagamento] =
    useState<FormaPagamento | null>({} as FormaPagamento);
  const [contratacaoPlanoFuturo, setContratacaoPlanoFuturo] =
    useState<ContratacaoPlano | null>({} as ContratacaoPlano);
  const [contratacaoFormaPagamento, setContratacaoFormaPagamento] =
    useState<FormaPagamento | null>({} as FormaPagamento);
  const [contratacaoFuturoFormaPagamento, setContratacaoFuturoFormaPagamento] =
    useState<FormaPagamento | null>({} as FormaPagamento);

  const [tipoStatus, setTipoStatus] = useState<{
    contratacaoAtiva: boolean;
    contratacaoTransitoria: boolean;
    tipoStatus: number;
  }>({ contratacaoAtiva: false, contratacaoTransitoria: false, tipoStatus: 0 });
  const [isContratacaoCancelada, setIsContratacaoCancelada] = useState(false);
  const [isPagamentoPendente, setIsPagamentoPendente] = useState(false);
  const [dtaDemonstracaoFim, setDtaDemonstracaoFim] = useState<string | null>(
    null,
  );
  const [codSeqPlano, setCodSeqPlano] = useState(0);
  const [codAplicacao, setCodAplicacao] = useState(0);
  const [desPlano, setDesPlano] = useState('');
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
  const [chaveBoleto, setChaveBoleto] = useState<string>('');
  const [boletoURL, setBoletoURL] = useState<string>('');
  const [tipoMudancaPlano, setTipoMudancaPlano] = useState<string>('');
  const [codCliente, setCodCliente] = useState<number>();
  const [valSelectedPlan, setValSelectedPlan] = useState<number>(0);
  const [tipoStatusLicenca, setTipoStatusLicenca] = useState<number>(0);
  const [isDowngrade, setIsDowgrade] = useState(false);
  const [codLoja, setCodLoja] = useState<any>(user.loja);

  const semanaDoConsumidorAtivo = ofertaAtivaSemanaConsumidor(
    new Date(2025, 2, 17),
    new Date(2025, 2, 29),
  );

  const setDemonstracaoOff = useCallback(() => {
    setIsDemonstracao(false);
  }, []);

  /**
   * Form Contratacao de Planos
   */
  const {
    register: registerClienteContratacao,
    control: controlClienteContratacao,
    setValue: setValueClienteContratacao,
    formState: formStateClienteContratacao,
    getValues: getValuesClienteContratacao,
    trigger: triggerClienteContratacao,
    reset: resetClienteContratacao,
    clearErrors: clearErrorsClienteContratacao,
  } = useForm({
    resolver: yupResolver(customerDataSchema), // Conecta o esquema ao formulário
    mode: 'onChange', // Validação reativa (opcional)
  });

  const {
    register: registerCard,
    control: controlCard,
    setValue: setValueCard,
    formState: formStateCard,
    reset: resetFormCard,
    getValues: getValuesCard,
    resetField: resetFieldCard,
    trigger: triggerCard,
    watch: watchCard,
  } = useForm({
    resolver: yupResolver(creditCardSchema),
    mode: 'onChange',
  });

  const formCard = {
    registerCard,
    controlCard,
    setValueCard,
    formStateCard,
    resetFormCard,
    getValuesCard,
    resetFieldCard,
    triggerCard,
    watchCard,
  };

  const formClienteContratacao = {
    registerClienteContratacao,
    controlClienteContratacao,
    setValueClienteContratacao,
    formStateClienteContratacao,
    getValuesClienteContratacao,
    triggerClienteContratacao,
    resetClienteContratacao,
    clearErrorsClienteContratacao,
  };

  const {
    isFetching: isFetchingContratacaoAtual,
    refetch: refetchContratacaoAtual,
  } = useQuery(
    'contratacao-atual',
    async () => {
      const currentHiring = await serviceMeuPlano.index('/contratacao-atual');

      // Descomente para usar o mock
      // const currentHiring = {
      //   success: true,
      //   data: [dataJson],
      // };

      if (currentHiring.success) {
        const {
          cod_seq_plano,
          cod_aplicacao,

          ciclo_atual,
          ciclo_futuro,
          ciclo_futuro_forma_pagamento,

          contratacao_cliente,
          contratacao_cliente_futuro,
          contratacao_plano,
          contratacao_plano_futuro,
          contratacao_atual_forma_pagamento,
          contratacao_futuro_forma_pagamento,

          flg_demonstracao,
          flg_ativo,

          tipo_status,

          dta_demonstracao_fim,

          cod_cliente,

          des_plano,
        } = currentHiring.data[0];

        setTipoStatusLicenca(tipo_status);

        setCodSeqPlano(cod_seq_plano);
        setCodAplicacao(cod_aplicacao);
        setCodCliente(cod_cliente);
        setDesPlano(des_plano);

        setCicloFuturo(ciclo_futuro);
        setCicloFuturoFormaPagamento(ciclo_futuro_forma_pagamento);
        setContratacaoFormaPagamento(contratacao_atual_forma_pagamento);
        setContratacaoFuturoFormaPagamento(contratacao_futuro_forma_pagamento);

        setContratacaoPlano(contratacao_plano);
        setContratacaoPlanoFuturo(contratacao_plano_futuro);

        setContratacaoCliente(contratacao_cliente);
        setContratacaoClienteFuturo(contratacao_cliente_futuro);

        const ativa = Number(contratacao_cliente.tipo_status) === 0;
        const transitoria = Number(contratacao_cliente.tipo_status) === 1;

        /** Verifica se é downgrade */
        if (!flg_demonstracao && transitoria && contratacao_cliente_futuro) {
          if (contratacao_cliente_futuro?.tipo_contratacao === 3) {
            setIsDowgrade(true);
          }
        } else {
          setIsDowgrade(false);
        }

        setCicloAtual(ciclo_atual);

        if (ciclo_atual.flg_atual) {
          setIsDemonstracao(flg_demonstracao);

          setPlanoPagamento(
            semanaDoConsumidorAtivo && flg_demonstracao
              ? 'ANUAL'
              : contratacao_cliente?.tipo_ciclo === 6
              ? 'ANUAL'
              : 'MENSAL',
          );

          setTipoStatus({
            contratacaoAtiva: ativa,
            contratacaoTransitoria: transitoria,
            tipoStatus: contratacao_cliente.tipo_status,
          });

          // setIsContratacaoCancelada(false);
          setIsContratacaoCancelada(
            ativa && // tipo_status = 0
              !flg_demonstracao &&
              contratacao_cliente &&
              contratacao_cliente.dta_fim !== null,
          );
          setIsPagamentoPendente(!flg_ativo);
          if (flg_demonstracao) {
            setDtaDemonstracaoFim(dta_demonstracao_fim);
            // console.log('Efetivacao em processo');
          } else if (!contratacao_cliente_futuro) {
            // console.log('Contratacao Atual pode fazer alteracoes');
          } else {
            // console.log('Contratacao Atual nao pode fazer alteracoes');
          }
        } else {
          0;
          // console.log('Regularizacao');
          setIsDemonstracao(false);
          setTipoStatus({
            contratacaoAtiva: false,
            contratacaoTransitoria: false,
            tipoStatus: contratacao_cliente.tipo_status,
          });

          setIsContratacaoCancelada(false);
          setIsPagamentoPendente(false);
        }
      }
    },
    // {
    //   refetchOnWindowFocus: false,
    // },
  );

  const handlePlanoPagamento = useCallback((plano: 'MENSAL' | 'ANUAL') => {
    setPlanoPagamento(plano);
  }, []);

  const handleSubScreen = useCallback((subscreen: SubScreenMeuPlano) => {
    setSubScreen(subscreen);
  }, []);

  const handleMethodFaturamento = useCallback(
    (method: MethodFaturamento | null) => {
      setMethodFaturamento(method);
    },
    [],
  );

  const fetchCustomerData = useCallback(async () => {
    try {
      if (!codLoja) {
        return {};
      }
      const response = await api.get(`/meu-plano/customer-data/${codLoja}`);
      const { success, data } = response.data;

      if (success && data.length > 0) {
        return data[0];
      }
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  }, [codLoja]);

  const {
    data: clienteData,
    refetch: refetchCustomer,
    // isLoading,
    // isError,
  } = useQuery<CustomerData>(['customerData', codLoja], fetchCustomerData, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    enabled: !!codLoja,
    refetchOnWindowFocus: false,
  });

  const setCustomerDataToForm = useCallback(() => {
    if (clienteData) {
      // Preenche form de cliente
      setValueClienteContratacao('des_nome', clienteData.nome_pessoa);
      setValueClienteContratacao('des_email', clienteData.email);
      setValueClienteContratacao('num_cpf_cnpj', clienteData.num_cpf_cnpj);
      if (clienteData.num_celular)
        setValueClienteContratacao(
          'num_telefone',
          formatUtils.formatCellphone(String(clienteData.num_celular)),
        );
      if (clienteData.num_cep)
        setValueClienteContratacao(
          'num_cep',
          formatUtils.formatCEP(clienteData.num_cep),
        );
      setValueClienteContratacao('des_rua', clienteData.des_logradouro);
      setValueClienteContratacao('des_numero', clienteData.num_endereco);
      setValueClienteContratacao(
        'des_complemento',
        clienteData.des_complemento,
      );
      setValueClienteContratacao('des_bairro', clienteData.des_bairro);
      setValueClienteContratacao('des_cidade', {
        value: clienteData.cod_cidade,
        ufValue: clienteData.uf,
        label: `${clienteData.des_cidade} - ${clienteData.uf}`,
        cidade: clienteData.des_cidade,
      });

      // preenche form de cartão
      setValueCard('des_nome', clienteData.nome_pessoa);
      setValueCard('des_email', clienteData.email);
      setValueCard('num_cpf_cnpj', clienteData.num_cpf_cnpj);
      setValueCard('num_cep', clienteData.num_cep);
      setValueCard('num_endereco', clienteData.num_endereco);
      setValueCard('des_complemento', clienteData.des_complemento);
      setValueCard('num_celular', clienteData.num_celular);
    }
  }, [clienteData, setValueCard, setValueClienteContratacao]);

  const handleScreen = useCallback(
    (newScreen: ScreenMeuPlano) => {
      if (newScreen === 'HOME') {
        resetClienteContratacao(resetClientData);
        resetFormCard(resetCreditCard);
        setCustomerDataToForm();
      }

      if (newScreen !== 'MUDAR-FORMA-PAGAMENTO') {
        setValueCard('tela', '');
      }
      setScreen(newScreen);
    },
    [
      resetClienteContratacao,
      resetFormCard,
      setCustomerDataToForm,
      setValueCard,
    ],
  );

  useEffect(() => {
    setCustomerDataToForm();
  }, [clienteData, setCustomerDataToForm]);

  useEffect(() => {
    if (user.loja) {
      setCodLoja(user.loja);
      refetchCustomer();
    }
  }, [refetchCustomer, user]);

  const [showLastCreditCard, setShowLastCreditCard] = useState(false);
  const [creditCard, setCreditCard] = useState<FormaPagamento>(
    {} as FormaPagamento,
  );

  useEffect(() => {
    if (
      contratacaoFormaPagamento &&
      contratacaoFormaPagamento.tipo_forma_pagamento === 1
    ) {
      setCreditCard(contratacaoFormaPagamento);
      setShowLastCreditCard(true);
      return;
    }
    if (
      contratacaoFuturoFormaPagamento &&
      contratacaoFuturoFormaPagamento.tipo_forma_pagamento === 1
    ) {
      setCreditCard(contratacaoFuturoFormaPagamento);
      setShowLastCreditCard(true);
      return;
    }
    setShowLastCreditCard(false);
  }, [contratacaoFormaPagamento, contratacaoFuturoFormaPagamento]);

  return (
    <MeuPlanoContext.Provider
      value={{
        isDemonstracao,

        screen,
        handleScreen,

        planoPagamento,
        handlePlanoPagamento,

        contratacaoPlano,
        contratacaoPlanoFuturo,

        isFetchingContratacaoAtual,

        subScreen,
        handleSubScreen,

        methodFaturamento,
        handleMethodFaturamento,

        contratacaoCliente,
        contratacaoClienteFuturo,

        cicloAtual,
        cicloFuturo,
        cicloFuturoFormaPagamento,

        tipoStatus,
        isContratacaoCancelada,
        isPagamentoPendente,

        dtaDemonstracaoFim,

        formCard,

        codSeqPlano,

        desPlano,
        codAplicacao,

        formClienteContratacao,

        selectedMethod,
        setSelectedMethod,
        chaveBoleto,
        setChaveBoleto,
        boletoURL,
        setBoletoURL,

        tipoMudancaPlano,
        setTipoMudancaPlano,
        codCliente,
        clienteData,
        refetchCustomer,
        setCustomerDataToForm,
        valSelectedPlan,
        setValSelectedPlan,
        tipoStatusLicenca,
        setTipoStatusLicenca,
        refetchContratacaoAtual,
        isDowngrade,
        contratacaoFuturoFormaPagamento,
        contratacaoFormaPagamento,

        showLastCreditCard,
        creditCard,
        codLoja,

        semanaDoConsumidorAtivo,
        setDemonstracaoOff,
      }}
    >
      {children}
    </MeuPlanoContext.Provider>
  );
};
